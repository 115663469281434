const NavBar = () => {
    return (
      <div className="navbar-section">
            <div className="logo-mob">
                <svg xmlns="http://www.w3.org/2000/svg" width="98" height="90" viewBox="0 0 98 90" fill="none">
                    <g clipPath="url(#clip0_1616_748)">
                        <path d="M10.7214 32.229C13.8174 32.229 16.2984 33.1775 18.2917 34.9165C18.9703 35.5137 19.2671 35.584 20.0093 34.9341C22.7024 32.5276 26.0741 31.6669 29.891 32.4749C33.6868 33.2653 36.3374 35.2327 37.3765 38.412C38.4156 41.5738 37.5886 44.4369 34.7682 46.8083C30.9089 50.0403 27.0071 53.2548 23.1053 56.4341C20.7091 58.3839 17.3587 58.4015 14.9625 56.4341C11.0819 53.2197 7.18009 50.0052 3.38434 46.7204C-2.06543 41.9953 0.33077 34.5476 7.85867 32.5627C8.83411 32.2992 9.85197 32.2114 10.7214 32.229Z" fill="#E5473D"/>
                        <path d="M66.1512 67.4652C67.5932 65.937 69.2472 64.8831 71.3253 64.3386C76.457 62.9685 83.073 65.252 84.4938 70.3987C85.3632 73.5604 84.4726 76.3006 81.7159 78.6017C77.8989 81.7811 74.082 84.9428 70.2438 88.087C67.784 90.1071 64.3911 90.1071 61.9525 88.1046C58.1356 84.9604 54.361 81.8162 50.6077 78.6368C47.8298 76.2831 46.7907 73.455 47.851 70.2757C48.9749 66.9383 51.7316 64.7777 55.8454 64.1278C59.2382 63.5833 62.3554 64.2859 64.9213 66.3235C65.3242 66.6397 65.6847 67.0437 66.1512 67.4652Z" fill="#E5473D"/>
                        <path d="M31.4592 76.8101C31.438 83.8889 24.4615 89.6328 15.9369 89.5977C7.41238 89.5626 0.457031 84.0646 0.457031 76.7574C0.457031 69.3272 7.70926 63.9522 15.9581 63.9346C24.4827 63.917 31.5441 69.7488 31.4592 76.8101ZM15.9793 81.2541C19.0117 81.2541 21.5351 79.1814 21.4927 76.7222C21.4503 74.2806 18.9481 72.2255 16.0005 72.2079C12.7349 72.1728 10.4872 74.5793 10.466 76.7398C10.466 79.3044 13.3923 81.5 15.9793 81.2541Z" fill="white"/>
                        <path d="M31.4592 13.3462C31.438 20.2669 24.8644 26.1689 15.9369 26.1513C7.20033 26.1338 0.478237 20.5304 0.457031 13.311C0.457031 5.89841 7.58203 0.400436 16.1278 0.470698C24.822 0.540959 31.4804 6.39025 31.4592 13.3462ZM15.9369 17.8253C18.9905 17.8253 21.62 15.7702 21.4715 13.2935C21.3231 10.5708 19.1177 8.76158 15.9369 8.74401C12.3744 8.72645 10.5084 11.2734 10.4872 13.2583C10.4447 15.7526 13.1166 17.9483 15.9369 17.8253Z" fill="white"/>
                        <path d="M82.1826 32.1588C90.7284 32.1939 97.6837 37.9553 97.6625 45.0166C97.6625 52.2009 90.6436 57.9448 81.8434 57.857C73.2976 57.7691 66.5755 51.7618 66.6391 44.8937C66.7451 38.2364 73.3188 32.1939 82.1826 32.1588ZM76.6905 45.0166C76.6905 47.5109 79.1715 49.6715 82.1402 49.6012C85.4058 49.531 87.6112 47.4758 87.6536 45.0518C87.6748 42.5399 85.2362 40.5023 82.1826 40.5023C79.1079 40.4848 76.6905 42.4872 76.6905 45.0166Z" fill="white"/>
                        <path d="M44.8828 76.8452C44.8828 80.7974 44.8616 84.7496 44.904 88.7018C44.904 89.2991 44.7768 89.5098 43.9922 89.4923C41.2779 89.4571 38.5424 89.4571 35.8281 89.4923C35.1496 89.4923 34.9375 89.3518 34.9375 88.7721C34.9587 80.7623 34.9587 72.7349 34.9375 64.725C34.9375 64.0927 35.2132 64.0224 35.8493 64.0224C38.5424 64.04 41.2143 64.0575 43.9074 64.0224C44.692 64.0048 44.904 64.1805 44.904 64.8304C44.8616 68.8353 44.8828 72.8403 44.8828 76.8452Z" fill="white"/>
                        <path d="M50.5656 45.0342C50.5656 48.9161 50.5444 52.8157 50.5868 56.6976C50.5868 57.4002 50.3535 57.5759 49.5265 57.5583C46.9182 57.5232 44.31 57.5232 41.6805 57.5583C41.0444 57.5583 40.8535 57.4529 40.8535 56.8908C40.8747 48.9688 40.8747 41.0468 40.8535 33.1073C40.8535 32.5803 41.002 32.4046 41.6593 32.4222C44.3524 32.4573 47.0243 32.4573 49.7173 32.4222C50.4807 32.4046 50.5656 32.6506 50.5656 33.1951C50.5444 37.1297 50.5656 41.082 50.5656 45.0342Z" fill="white"/>
                        <path d="M53.5993 44.9112C53.5993 41.0117 53.6205 37.1297 53.5781 33.2302C53.5781 32.5451 53.8114 32.4046 54.596 32.4222C57.2042 32.4573 59.8337 32.4573 62.442 32.4222C63.0993 32.4222 63.3538 32.5276 63.3538 33.1424C63.3326 41.0468 63.3326 48.9512 63.3538 56.8557C63.3538 57.3475 63.269 57.5759 62.5692 57.5759C59.8549 57.5407 57.1194 57.5407 54.4051 57.5759C53.7054 57.5934 53.5993 57.3827 53.5993 56.8733C53.6205 52.8683 53.5993 48.881 53.5993 44.9112Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1616_748">
                        <rect width="97.2689" height="89.127" fill="white" transform="translate(0.416016 0.470703)"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
      </div>
    );
  };
export default NavBar 
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faFlask } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function Comp() {
    // const { talentid, testid } = useParams();
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const talentid = params.get('token');
    const partner = params.get('partner');
    const testid = params.get('test');
    const [loadingTests, setLoadingTests] = useState(true);
    useEffect(() => {
        setLoadingTests(true);
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        axios.put(
        `${backendUrl}/updatesatatuses`,{
            token: talentid,
            test: testid,
            partner: partner,
            status: 2,
        },
        {
            headers: {
            'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo',
            'Content-Type': 'application/json', 
            },
        }
        )
        .then(response => {
            // Redirection vers un autre projet React
            window.location.href = '/?token='+talentid;
        })
        .catch(error => {
        // Gérer les erreurs
        console.error(error);
        });

      // Your additional logic here
    }, [talentid, testid]); 
    
  return (
    <>
      {loadingTests ? ( // Show loader while loading tests
          <div className="loader-container">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            <p>Chargement...</p>
          </div>
      ) : (
        <div className="Comp">

        </div>
      )}
    </>
  );
}

export default Comp;

import React from 'react'
import NavBar from '../NavBar/NavBar'
import MobileContent from '../MobileContent/MobileContent'
export default function Mobile() {
  return (
    <>
        <NavBar/>
        <MobileContent/>
    </>
  )
}

const RightSection = () => {
    return (
      <div className="right-section">
        {
          <div className='app-name'>
            <div className='logo-title'>
              <svg width="220" height="42" viewBox="0 0 286 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1744_248)">
                <path d="M27.5693 23.532L18.1774 14.2979C17.5714 13.7022 16.814 13.4043 15.9051 13.4043H0.150879V19.3617H17.4199C18.7833 19.3617 19.9952 19.8086 20.9041 20.8511C21.813 21.7447 22.4189 22.9362 22.4189 24.2766V41.2554H28.4782V25.6171C28.4782 24.8724 28.1752 24.1277 27.5693 23.532Z" fill="white"/>
                <path d="M44.3845 13.4043C43.6271 13.4043 42.8697 13.7022 42.2637 14.2979L32.8718 23.532C32.2659 24.1277 31.9629 24.8724 31.9629 25.6171V41.1064H38.0222V24.1277C38.0222 21.4468 40.2945 19.2128 43.0212 19.2128H60.2902V13.4043H44.3845Z" fill="white"/>
                <path d="M101.493 12.6597C98.6148 12.6597 95.5852 14.7448 94.2218 17.5746V17.7235H94.0704H93.9189H93.7674V17.5746V13.4043H89.2229V41.2554H94.0704V28.5958C94.0704 21.4469 97.2515 16.9788 102.099 16.9788C103.159 16.9788 104.068 17.1278 104.826 17.4256V13.1065C103.765 12.8086 103.008 12.6597 101.493 12.6597Z" fill="white"/>
                <path d="M250.25 27.7022V27.8512V30.3831C250.25 32.6171 249.492 34.4043 248.129 35.8937C246.766 37.3831 244.645 38.1278 242.07 38.1278C240.555 38.1278 238.889 37.532 237.828 36.4895C236.919 35.5958 236.465 34.5533 236.465 33.3618C236.465 28.298 243.585 27.7022 246.614 27.7022C247.22 27.7022 247.675 27.7022 248.281 27.7022C248.886 27.7022 249.341 27.7022 249.947 27.7022H250.25ZM252.219 15.3405C250.401 13.5533 247.675 12.6597 243.736 12.6597C240.555 12.6597 237.525 13.5533 234.496 15.1916L234.799 19.3618C236.919 17.7235 240.1 16.532 242.979 16.532C245.402 16.532 247.22 17.1278 248.432 18.1703C249.644 19.3618 250.25 21.298 250.25 23.9788V24.1278H250.098C247.978 23.9788 246.614 23.9788 244.493 23.9788C241.009 23.9788 236.313 24.8724 233.587 27.5533C232.072 29.0426 231.314 30.8299 231.314 33.0639C231.314 35.5958 232.072 37.6809 233.587 39.0214C235.253 40.6597 238.131 41.7022 241.615 41.7022C246.766 41.7022 249.341 38.7235 250.25 37.532H250.401H250.553H250.704V37.6809V41.1065H255.097V24.1278C255.097 20.1065 254.188 17.2767 252.219 15.3405Z" fill="white"/>
                <path d="M123.004 27.7022V27.8512V30.3831C123.004 32.6171 122.246 34.4043 120.883 35.8937C119.52 37.3831 117.399 38.1278 114.824 38.1278C113.309 38.1278 111.643 37.532 110.582 36.4895C109.673 35.5958 109.219 34.5533 109.219 33.3618C109.219 28.298 116.338 27.7022 119.368 27.7022C119.974 27.7022 120.429 27.7022 121.034 27.7022C121.64 27.7022 122.095 27.7022 122.701 27.7022H123.004ZM125.125 15.3405C123.307 13.5533 120.58 12.6597 116.641 12.6597C113.46 12.6597 110.431 13.5533 107.401 15.1916L107.552 19.3618C109.673 17.7235 112.854 16.532 115.733 16.532C118.156 16.532 119.974 17.1278 121.186 18.1703C122.398 19.3618 123.004 21.298 123.004 23.9788V24.1278H122.852C120.731 23.9788 119.368 23.9788 117.247 23.9788C113.763 23.9788 109.067 24.8724 106.341 27.5533C104.826 29.0426 104.068 30.8299 104.068 33.0639C104.068 35.5958 104.826 37.6809 106.341 39.0214C108.007 40.6597 110.885 41.7022 114.369 41.7022C119.52 41.7022 122.095 38.7235 123.004 37.532H123.155H123.307H123.458V37.6809V41.1065H127.851V24.1278C127.851 20.1065 126.942 17.2767 125.125 15.3405Z" fill="white"/>
                <path d="M154.361 15.3405C152.543 13.5533 150.119 12.8086 146.938 12.8086C142.848 12.8086 139.213 14.7448 137.698 17.8724V18.0214H137.546H137.395H137.243V17.8724V13.4043H132.547V41.1065H137.395V28.298C137.395 24.7235 138.152 21.8937 139.516 19.8086C141.03 17.5746 143.303 16.532 146.332 16.532C150.877 16.8299 152.543 19.6597 152.543 26.8086V41.2554H157.391V24.5745C157.391 20.4043 156.33 17.2767 154.361 15.3405Z" fill="white"/>
                <path d="M202.078 25.1703C199.351 23.9788 196.776 22.7873 196.776 20.1065C196.776 17.8724 198.745 16.532 201.926 16.532C203.138 16.532 206.016 17.1278 207.38 17.8724L207.834 13.7022C205.714 13.1065 203.744 12.6597 201.472 12.6597C198.139 12.6597 195.564 13.5533 193.746 15.3405C192.232 16.8299 191.474 18.766 191.474 21.149C191.474 25.4682 195.261 27.2554 198.594 28.8937C201.321 30.2341 203.896 31.4256 203.896 33.8086C203.896 34.8512 203.593 35.7448 202.835 36.4895C201.472 37.8299 199.2 37.9788 198.442 37.9788C197.079 37.9788 194.201 37.3831 192.08 36.0426L191.777 40.2129C194.352 41.5533 197.23 41.7022 199.503 41.7022C202.078 41.7022 204.502 40.9575 206.319 39.6171C208.289 38.1278 209.349 35.8937 209.349 33.0639C209.198 28.298 205.411 26.6597 202.078 25.1703Z" fill="white"/>
                <path d="M226.315 37.9788C225.103 37.9788 224.043 37.532 223.134 36.7873C222.225 35.8937 221.619 34.4044 221.619 32.7661V17.4256V17.2767H221.771H229.193V13.4044H221.771H221.619V13.2554V5.36182L216.772 6.85118V13.2554V13.4044H216.62H210.409V17.2767H216.62H216.772V17.4256V33.9576C216.772 36.4895 217.529 38.4256 218.892 39.7661C220.407 41.2554 222.528 41.8512 225.406 41.8512C227.073 41.8512 228.739 41.4044 229.648 41.1065V37.0852C228.739 37.681 227.527 37.9788 226.315 37.9788Z" fill="white"/>
                <path d="M280.85 27.4042C280.85 30.8298 279.638 33.9574 277.668 35.8936C276.154 37.383 274.336 38.1276 272.215 38.1276C270.246 38.1276 268.579 37.383 267.216 36.0425C264.792 33.6596 264.035 29.7872 264.035 27.2553C263.883 23.6808 264.944 20.5532 266.913 18.617C268.276 17.2766 269.943 16.5319 272.064 16.5319C274.184 16.5319 276.002 17.2766 277.517 18.7659C279.789 20.7021 280.85 23.8298 280.85 27.4042ZM280.85 0.148926V16.9787V17.1276H280.698H280.547H280.395V16.9787C278.577 14 275.396 12.5106 271.003 12.5106C267.671 12.5106 264.944 13.5532 262.823 15.6383C260.248 18.1702 258.885 22.0425 258.885 26.5106C258.885 31.5745 260.399 35.8936 263.126 38.5745C265.247 40.6596 267.822 41.5532 271.003 41.5532C275.851 41.5532 279.183 39.1702 280.547 37.0851V36.9362H280.698H280.85H281.001V37.0851V40.8085H285.697V0.148926H280.85Z" fill="white"/>
                <path d="M183.294 27.4042C183.294 30.8298 182.082 33.9574 180.113 35.8936C178.598 37.383 176.78 38.1276 174.659 38.1276C172.69 38.1276 171.024 37.383 169.66 36.0425C167.237 33.6596 166.479 29.7872 166.479 27.2553C166.328 23.6808 167.388 20.5532 169.357 18.617C170.721 17.2766 172.387 16.5319 174.508 16.5319C176.629 16.5319 178.446 17.2766 179.961 18.7659C182.082 20.7021 183.294 23.8298 183.294 27.4042ZM183.294 0.148926V16.9787V17.1276H183.142H182.991H182.839V16.9787C181.022 14 177.841 12.5106 173.448 12.5106C170.115 12.5106 167.388 13.5532 165.267 15.6383C162.692 18.1702 161.329 22.0425 161.329 26.5106C161.329 31.5745 162.844 35.8936 165.57 38.5745C167.691 40.6596 170.266 41.5532 173.448 41.5532C178.295 41.5532 181.628 39.1702 182.991 37.0851V36.9362H183.142H183.294H183.445V37.0851V40.8085H188.141V0.148926H183.294Z" fill="white"/>
                <path d="M22.268 41.4041H28.6303V25.6168C28.6303 24.7232 28.3273 23.9785 27.7214 23.3828L18.178 14.1487C17.572 13.553 16.8146 13.2551 15.9057 13.2551H0V19.5104H17.4205C19.9958 19.5104 22.1165 21.5956 22.1165 24.1275V41.4041H22.268Z" fill="white"/>
                <path d="M38.022 41.4041H31.6597V25.6168C31.6597 24.7232 31.9626 23.9785 32.5686 23.3828L41.9605 14.1487C42.5664 13.553 43.3239 13.2551 44.2328 13.2551H60.1385V19.5104H42.8694C40.2942 19.5104 38.1734 21.5956 38.1734 24.1275V41.4041H38.022Z" fill="white"/>
                <path d="M88.9209 13.2554H93.9198V17.5746H94.0713C95.4347 14.5958 98.4643 12.5107 101.494 12.5107C103.009 12.5107 103.918 12.6597 104.978 12.9576V17.5746C104.069 17.1278 103.009 17.1278 102.1 17.1278C97.4039 17.1278 94.3743 21.5958 94.3743 28.5958V41.4044H89.0724V13.2554H88.9209Z" fill="white"/>
                <path d="M249.947 30.3831C249.947 34.5533 247.221 37.8299 242.07 37.8299C239.646 37.8299 236.768 36.1916 236.768 33.2129C236.768 28.149 243.888 27.7022 246.615 27.7022C247.675 27.7022 248.887 27.8512 249.947 27.8512V30.3831ZM234.647 19.6597C236.768 17.8724 239.949 16.681 242.979 16.681C248.281 16.681 250.099 19.2129 250.099 24.1278C247.978 23.9788 246.615 23.9788 244.494 23.9788C239.04 23.9788 231.163 26.2129 231.163 33.2129C231.163 39.3192 235.556 42.0001 241.616 42.0001C246.463 42.0001 249.19 39.4682 250.402 37.6809H250.553V41.2554H255.249V24.1278C255.249 16.3831 251.916 12.5107 243.736 12.5107C240.101 12.5107 236.92 13.5533 234.344 15.1916L234.647 19.6597Z" fill="white"/>
                <path d="M122.853 30.3831C122.853 34.5533 120.126 37.8299 114.976 37.8299C112.552 37.8299 109.674 36.1916 109.674 33.2129C109.674 28.149 116.793 27.7022 119.52 27.7022C120.58 27.7022 121.792 27.8512 122.853 27.8512V30.3831ZM107.401 19.6597C109.522 17.8724 112.703 16.681 115.733 16.681C121.035 16.681 122.853 19.2129 122.853 24.1278C120.732 23.9788 119.369 23.9788 117.248 23.9788C111.794 23.9788 103.917 26.2129 103.917 33.2129C103.917 39.3192 108.31 42.0001 114.37 42.0001C119.217 42.0001 121.944 39.4682 123.156 37.6809H123.307V41.2554H128.003V24.1278C128.003 16.3831 124.67 12.5107 116.49 12.5107C112.855 12.5107 109.674 13.5533 107.098 15.1916L107.401 19.6597Z" fill="white"/>
                <path d="M132.396 13.2554H137.395V17.7235H137.546C139.061 14.5958 143 12.6597 146.938 12.6597C154.361 12.6597 157.694 17.1278 157.694 24.7235V41.4043H152.392V26.8086C152.392 20.2554 150.877 16.9788 146.332 16.6809C140.273 16.6809 137.698 21.4469 137.698 28.298V41.2554H132.396V13.2554Z" fill="white"/>
                <path d="M191.778 35.8937C193.898 37.2341 196.776 37.8299 198.291 37.8299C200.715 37.8299 203.593 36.7873 203.593 33.8086C203.593 28.5958 191.172 29.0427 191.172 21.149C191.172 15.3405 195.565 12.5107 201.321 12.5107C203.745 12.5107 205.865 12.9576 207.835 13.5533L207.38 18.0214C206.168 17.2767 203.139 16.532 201.775 16.532C199.049 16.532 196.776 17.5746 196.776 19.9575C196.776 25.7661 209.198 24.1278 209.198 33.0639C209.198 39.0214 204.351 41.8512 199.2 41.8512C196.474 41.8512 193.747 41.5533 191.323 40.3618L191.778 35.8937Z" fill="white"/>
                <path d="M229.497 17.4254H221.923V32.7658C221.923 36.0424 223.892 37.8297 226.316 37.8297C227.982 37.8297 229.194 37.3829 229.951 36.7871V41.1063C228.739 41.4041 227.073 41.8509 225.407 41.8509C219.953 41.8509 216.621 39.319 216.621 33.6595V17.4254H210.107V13.2552H216.621V6.70202L221.923 5.06372V13.2552H229.497V17.4254Z" fill="white"/>
                <path d="M264.338 27.1064C264.186 21.8936 266.458 16.6808 272.215 16.6808C277.971 16.6808 280.698 22.0425 280.698 27.2553C280.698 32.0213 278.274 37.8298 272.366 37.8298C266.61 37.8298 264.338 31.5745 264.338 27.1064ZM280.849 41.4043H285.848V0H280.546V16.9787H280.395C278.728 14.4468 275.85 12.5106 271.003 12.5106C262.671 12.5106 258.733 19.2128 258.733 26.6596C258.733 34.4043 262.368 41.8511 271.003 41.8511C276.153 41.8511 279.334 39.3191 280.698 37.234H280.849V41.4043Z" fill="white"/>
                <path d="M166.783 27.1064C166.631 21.8936 168.903 16.6808 174.66 16.6808C180.416 16.6808 183.143 22.0425 183.143 27.2553C183.143 32.0213 180.719 37.8298 174.811 37.8298C169.055 37.8298 166.631 31.5745 166.783 27.1064ZM183.294 41.4043H188.293V0H182.991V16.9787H182.84C181.173 14.4468 178.295 12.5106 173.448 12.5106C165.116 12.5106 161.178 19.2128 161.178 26.6596C161.178 34.4043 164.813 41.8511 173.448 41.8511C178.598 41.8511 181.779 39.3191 183.143 37.234H183.294V41.4043Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_1744_248">
                <rect width="286" height="42" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <div className='logo-img'>
              <svg width="193" height="193" viewBox="0 0 259 287" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1744_278)">
                <path d="M27.4414 102.266C35.6851 102.266 42.2914 105.32 47.599 110.92C49.4059 112.843 50.1964 113.069 52.1726 110.976C59.3435 103.227 68.3213 100.456 78.4848 103.058C88.5918 105.603 95.6498 111.938 98.4166 122.176C101.183 132.357 98.9812 141.577 91.4715 149.213C81.1951 159.62 70.8057 169.971 60.4163 180.209C54.0359 186.488 45.1146 186.544 38.7342 180.209C28.4013 169.858 18.0119 159.507 7.90489 148.93C-6.60633 133.715 -0.225912 109.732 19.8188 103.34C22.4161 102.492 25.1264 102.209 27.4414 102.266Z" fill="#E5473D"/>
                <path d="M175.038 215.731C178.877 210.81 183.282 207.416 188.815 205.662C202.479 201.251 220.096 208.604 223.879 225.177C226.194 235.358 223.823 244.182 216.482 251.592C206.319 261.829 196.155 272.011 185.935 282.135C179.386 288.64 170.351 288.64 163.858 282.192C153.695 272.067 143.644 261.943 133.65 251.705C126.253 244.125 123.486 235.019 126.309 224.781C129.302 214.034 136.642 207.077 147.596 204.984C156.631 203.23 164.931 205.493 171.763 212.054C172.836 213.072 173.796 214.373 175.038 215.731Z" fill="#E5473D"/>
                <path d="M82.6633 245.822C82.6068 268.617 64.0302 287.113 41.3317 287C18.6332 286.887 0.113037 269.183 0.113037 245.653C0.113037 221.727 19.4237 204.418 41.3882 204.362C64.0867 204.305 82.8891 223.084 82.6633 245.822ZM41.4446 260.133C49.519 260.133 56.2382 253.458 56.1252 245.539C56.0123 237.677 49.3496 231.059 41.5011 231.003C32.8056 230.89 26.8205 238.639 26.764 245.596C26.764 253.854 34.556 260.925 41.4446 260.133Z" fill="white"/>
                <path d="M82.663 41.4605C82.6066 63.7463 65.1028 82.7514 41.3315 82.6949C18.0683 82.6383 0.169257 64.5947 0.112793 41.3474C0.112793 17.4779 19.0847 -0.226305 41.8396 -5.31203e-05C64.9898 0.226198 82.7195 19.0616 82.663 41.4605ZM41.3315 55.8841C49.4623 55.8841 56.4638 49.2662 56.0685 41.2908C55.6733 32.5236 49.801 26.6976 41.3315 26.6411C31.8455 26.5845 26.8767 34.7861 26.8202 41.1777C26.7073 49.2096 33.8218 56.28 41.3315 55.8841Z" fill="white"/>
                <path d="M217.725 102.04C240.48 102.153 259 120.705 258.944 143.444C258.944 166.578 240.254 185.074 216.822 184.791C194.067 184.508 176.167 165.164 176.337 143.048C176.619 121.61 194.123 102.153 217.725 102.04ZM203.101 143.444C203.101 151.476 209.707 158.433 217.612 158.206C226.307 157.98 232.18 151.362 232.293 143.557C232.349 135.468 225.856 128.907 217.725 128.907C209.538 128.85 203.101 135.299 203.101 143.444Z" fill="white"/>
                <path d="M118.405 245.935C118.405 258.662 118.349 271.389 118.462 284.115C118.462 286.038 118.123 286.717 116.034 286.661C108.806 286.547 101.522 286.547 94.2951 286.661C92.4882 286.661 91.9236 286.208 91.9236 284.341C91.98 258.549 91.98 232.7 91.9236 206.907C91.9236 204.871 92.6576 204.644 94.3515 204.644C101.522 204.701 108.637 204.758 115.808 204.644C117.897 204.588 118.462 205.153 118.462 207.246C118.349 220.143 118.405 233.039 118.405 245.935Z" fill="white"/>
                <path d="M133.537 143.5C133.537 156 133.481 168.557 133.593 181.058C133.593 183.32 132.972 183.886 130.77 183.829C123.825 183.716 116.88 183.716 109.879 183.829C108.185 183.829 107.677 183.49 107.677 181.68C107.733 156.17 107.733 130.66 107.677 105.094C107.677 103.397 108.072 102.831 109.822 102.888C116.993 103.001 124.108 103.001 131.278 102.888C133.311 102.831 133.537 103.623 133.537 105.377C133.481 118.047 133.537 130.773 133.537 143.5Z" fill="white"/>
                <path d="M141.611 143.104C141.611 130.547 141.668 118.047 141.555 105.49C141.555 103.284 142.176 102.831 144.265 102.888C151.21 103.001 158.212 103.001 165.157 102.888C166.907 102.888 167.585 103.227 167.585 105.207C167.528 130.66 167.528 156.113 167.585 181.567C167.585 183.151 167.359 183.886 165.496 183.886C158.268 183.773 150.984 183.773 143.757 183.886C141.894 183.942 141.611 183.264 141.611 181.623C141.668 168.727 141.611 155.887 141.611 143.104Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_1744_278">
                <rect width="259" height="287" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        }
      </div>
    );
  };

  export default RightSection;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faFlask } from '@fortawesome/free-solid-svg-icons';


const LeftSection = () => {
  const [NoticeToken, setNoticeToken] = useState([]);
  const [token, settoken] = useState([]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
  
    if (params.get('token') === null) {
      const noticeToken = params.get('notice');
      settoken(noticeToken);
      setNoticeToken(true);
    } else {
      const userToken = params.get('token');
      settoken(userToken);
      setNoticeToken(false);
    }
  }, []); 
  const [tests, setTests] = useState([]);
  const [NB, setNb] = useState([]);
  const [Done, setDone] = useState([]);
  const [name, setName] = useState([]);
  const [loadingTests, setLoadingTests] = useState(true);
  useEffect(() => {
    const fetchTests = async () => {
      try {
        setLoadingTests(true);
        if (token.length != 0) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/gettestsforuser/${token}`,
            {
              headers: {
                'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo',
                'Content-Type': 'application/json', 
              },
            }
          );
          const responsecpt = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/getnbtestfortalent/${token}`,
            {
              headers: {
                'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo',
                'Content-Type': 'application/json', 
              },
            }
          );
          
          const responsecheck = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/checkifallTestfortalentisdone/${token}`,
            {
              headers: {
                'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo',
                'Content-Type': 'application/json', 
              },
            }
          );
          const responsename = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/getdatatalent/${token}`,
            {
              headers: {
                'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo',
                'Content-Type': 'application/json', 
              },
            }
          );

          if (response.status !== 200) {
            throw new Error('Erreur lors de la récupération des données');
          }
          setTests(response.data);
          if (responsecpt.status !== 200) {
            throw new Error('Erreur');
          }
          setNb(responsecpt.data.cpt);
          if (responsename.status !== 200) {
            throw new Error('Erreur');
          }
          setName(responsename.data.Name);
          setLoadingTests(false);
          if (responsecheck.status !== 200) {
            throw new Error('Erreur');
          }
          setDone(responsecheck.data.Done);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      } finally{
      }
    };

    fetchTests();
  }, [token]); 
  const [starFillColors, setStarFillColors] = useState(Array(6).fill('#FEB616'));

  const handleStarClick = (index) => {
    const newFillColors = [...starFillColors];
    newFillColors[index] = newFillColors[index] === '#FEB616' ? '#D9D9D9' : '#FEB616';
    setStarFillColors(newFillColors);
  };
  const [loading, setLoading] = useState(false);
  const [textareaContent, setTextareaContent] = useState('');
  const [isTextareaClicked, setIsTextareaClicked] = useState(false);

  const handleTextareaClick = () => {
    setIsTextareaClicked(true);
  };
  const handleTextareaBlur = () => {
    setIsTextareaClicked(false);
  };
  // compter le nombre d'etoiles
  const countFilledStars = starFillColors.filter(color => color === '#FEB616').length;
  const handleSendNoticeClick = async () => {
    setLoading(true);
    try {
    const responsepostnotice = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/updategooglesheetlisttalent`,
      {
        id_talent: `${token}`,
        Comments: textareaContent,
        starts: countFilledStars,
      },
      {
        headers: {
          'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo',
          'Content-Type': 'application/json',
        },
      }
    );
    if (responsepostnotice.status !== 200) {
      throw new Error('Erreur');
    }
    if(responsepostnotice.data.Done==true){
        //cacher la div avec l'id note et afficher la div avec id thanks
        document.getElementById('thanks').style.display = 'block';
        document.getElementById('note').style.display = 'none';
    }
  } catch (error) {
    console.error('Error sending notice:', error);
  } finally {
    setLoading(false);
  }
  };

  const handleClick = async (index, link, idtest) => {
    // Créez une copie immuable du tableau tests
    const updatedTests = [...tests];

    // Modifiez la propriété loading pour l'élément spécifique
    updatedTests[index].loading = true;
  
    // Mettez à jour l'état avec la nouvelle copie mise à jour
    setTests(updatedTests);
  
    try {
      const responseupdate = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/updatesatatuses`,
        {
          token: `${token}`,
          test: idtest,
          status: 0,
        },
        {
          headers: {
            'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo',
            'Content-Type': 'application/json',
          },
        }
      );
      // Votre code de gestion de la réponse...
    } catch (error) {
      console.error('Error:', error);
    } finally {
      // Rétablissez la propriété loading après la fin de l'opération (même en cas d'erreur)

    }

    window.location.href = link;
  };
  return (
    <>
      {loadingTests ? ( // Show loader while loading tests
      <div className="loader-container">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        <p>Chargement...</p>
      </div>
      ) : (
        <>
            <div className="left-section">
              <h3 id="welcome_title">Bienvenue {name}, </h3>
              {NoticeToken === false ? (
              <>
                <div className="paragraph-infos">
                  <p className="m-0">Nous avons identifiés {NB} test(s) rapides qui pourraient nous aider pour vous trouver de supers missions.</p>
                  <p className="m-0">aucune obligation de faire le(s) {NB} test(s) tout de suite, vous pouvez revenir sur ce site pour passer vos tests.</p>
                  <p className="m-0">n’oubliez pas que au plus vite j’ai vos tests, au plus vite je peux présenter votre dossier chez nos clients. Bonne découverte !</p>
                </div>
                <div className="tests">
                  {tests.map((test, index) => (
                    test.parent_name && (test.parent_name.id==test.test.parent_id)  ?<div key={index} id="test-content">
                      <div className="img-test">
                        {test.test.img_name? <img src={`${test.test.img_name}`} className="img-test" alt="image" />:<img src="testrandstad.PNG" className="img-test" alt="image" />}
                      </div>
                      <div className="infos-test">
                        <div className="informations">
                          <h3 className="title-info">{test.parent_name.name}</h3>
                          <p className="p-info">
                            Niveau: {test.test.name}
                          </p>
                          <p className="p-info">
                            {test.test.description}
                          </p>
                        </div>
                        {test.test.done ?<button className="btn-ok">Test Ok</button>:<a href={test.test.link_test}  onClick={(e) => { e.preventDefault(); handleClick(index, test.test.link_test, test.test.uuid); }}> 
                          <button className="btn-lunchtest">
                            {test.loading  ? (
                              <FontAwesomeIcon icon={faSpinner} spin size="1x" />
                            ) : (
                              "Lancer le test"
                            )}
                          </button>
                        </a>}
                      </div>
                    </div>: 
                    <div key={index} id="test-content">
                    <div className="img-test">
                      {test.test.img_name? <img src={`${test.test.img_name}`} className="img-test" alt="image" />:<img src="testrandstad.PNG" className="img-test" alt="image" />}
                    </div>
                    <div className="infos-test">
                      <div className="informations">
                        <h3 className="title-info">{test.test.name}</h3>
                        <p className="p-info">
                          {test.test.description}
                        </p>
                      </div>
                      {test.test.done ?<button className="btn-ok">Test Ok</button>:<a href={test.test.link_test}  onClick={(e) => { e.preventDefault(); handleClick(index, test.test.link_test, test.test.uuid); }}> 
                        <button className="btn-lunchtest">
                          {test.loading  ? (
                            <FontAwesomeIcon icon={faSpinner} spin size="1x" />
                          ) : (
                            "Lancer le test"
                          )}
                        </button>
                      </a>}
                    </div>
                  </div>
                  ))}
                </div>
              </>):null}
              {Done === true || NoticeToken === true ? (
              <div id="note">
                <div className="notice text-center">
                  <h3 className="notice-title">Super ! tous vos tests sont ok, merci !</h3>
                  <span className="notice-question">Est-ce que tu pourrais nous donner ton avis sur les tests que tu viens de passer ?</span>
                </div>
                <div className="stars">
                  {starFillColors.map((fillColor, index) => (
                    <div key={index} className="star" onClick={() => handleStarClick(index)} style={{ cursor: 'pointer' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="78" viewBox="0 0 81 78" fill="none">
                        <path d="M40.5 0L50.0418 29.7123H80.9199L55.939 48.0755L65.4809 77.7877L40.5 59.4245L15.5191 77.7877L25.061 48.0755L0.0800972 29.7123H30.9582L40.5 0Z" fill={fillColor} />
                      </svg>
                    </div>
                  ))}
                </div>
                <div className='text-center' id="commentsdiv">
                  <textarea 
                    className="comments" 
                    placeholder="Un commentaire ?" 
                    value={textareaContent} 
                    onChange={(e) => setTextareaContent(e.target.value)}
                    onClick={handleTextareaClick}
                    onBlur={handleTextareaBlur}
                    style={{ backgroundColor: isTextareaClicked ? 'white' : '#D9D9D9' }}
                  ></textarea>
                  {loading ? (
                      <button className='sendnotice' >
                        <FontAwesomeIcon icon={faSpinner} spin size='1x' />
                      </button>
                    ) : (
                      <button className='sendnotice' onClick={handleSendNoticeClick}>Envoyer</button>
                    )}
                </div>
              </div>
              ) : null}
              <div id="thanks">
                <h2>Merci d'avoir laissé votre avis .😊</h2>
              </div>
              <div id="nav_talent">
                <a href='https://www.randstad.fr/donnees-personnelles/'>Notification concernant les traitements de données personnelles.</a> 
              </div>
            </div>
        </>
      )}
    </>
  );
};

export default LeftSection;

import LeftSection from "../LeftSection/LeftSection";
import RightSection from "../RightSection/RightSection";

const Layout = (props) => {
    return (
      <>
        <RightSection/>
        <LeftSection>
            {props.children}
        </LeftSection>
      </>
    )
};

export default Layout;
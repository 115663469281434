import React from 'react';
import Layout from '../../Components/Layout/Layout'


export default function Home() {
   return (
    <div className='content d-flex'>
        <Layout>
        </Layout>   
    </div>
  )
}

import './App.css';
import { useMediaQuery } from 'react-responsive';
import Home from './Pages/Home/Home';
import Mobile from './Pages/Mobile/Mobile';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Comp from "./Components/Comp/Comp";
function App() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  let bool =false;
  if(params.get('partner')!=null)
  {
      bool=true
  }
  return (
    <Router>
      <Routes>
        {bool && <Route path="/" element={<Comp />} />}
        {/* http://talent-randstad.techitaway.co:8092/?token=1ac07dc9-df3f-47d2-92f8-818e25e8a3e9&partner=trimoji&test=testID */}
        <Route path='/' element={
          <>
            {isMobile && <Mobile />}
            {isTablet && <Home />}
            {!isMobile && !isTablet && <Home />}
          </>
        } />
      </Routes>
    </Router>
  );
}

export default App;
